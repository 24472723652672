<template>
  <v-container fluid class="pa-0">
    <v-toolbar dense>
      <template v-slot:default>
        <v-tabs v-model="tab" class="elevation-2" grow>
          <v-tab v-for="item in tabs" :key="item.id" @click="clearSelected">
            {{ item.name }}
          </v-tab>
          <v-btn text class="text-center mr-2 mt-2" @click="add_point" v-if="$auth.check('superadmin')"
            >Добавить ЭЗС</v-btn
          >
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab" class="elevation-3">
      <v-tab-item key="list" eager>
        <v-container fluid class="">
          <v-row>
            <v-col class="col-12 col-sm-12 col-md-3"
              ><v-text-field
                hide-details
                label="Номер"
                v-model="number_filter"
              ></v-text-field
            ></v-col>
            <v-col class="col-12 col-sm-12 col-md-3"
              ><v-select
                hide-details
                v-model="city_filter"
                :items="city_items"
                label="Город"
                clearable
              ></v-select
            ></v-col>
            <v-col class="col-12 col-sm-12 col-md-3"
              ><v-select
                hide-details
                v-model="vendor_filter"
                :items="vendor_items"
                label="Производитель"
                clearable
              ></v-select
            ></v-col>
            <v-col class="col-12 col-sm-12 col-md-3"
              ><v-select
                hide-details
                v-model="fw_filter"
                :items="fw_items"
                label="Прошивка"
                clearable
              ></v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-data-table
                :headers="cp_header"
                :items="charge_points"
                item-key="point_number"
                class="elevation-1"
                :loading="points_loading"
                :custom-filter="filterOnlyCapsText"
                :server-items-length="total_points"
                :options.sync="options"
                :items-per-page="25"
                :item-class="row_class"
                height="74vh"
                :footer-props="{
                  'items-per-page-options': [10, 25, 50, 100, -1],
                }"
                @click:row="point_info"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item eager>
        <div ref="googleMap" id="map"></div>
      </v-tab-item>
    </v-tabs-items>
    <!--    <point-info-drawer :drawer="selected" v-on:closeDrawer="clearSelected" />-->
    <add-point-tab v-on:refresh="refresh_list"></add-point-tab>
  </v-container>
</template>

<script>
// import PointInfoDrawer from "@/views/points/PointInfoDrawer";
import AddPointTab from "@/views/points/AddPointTab";

export default {
  name: "List_points",
  components: {
    // PointInfoDrawer,
    AddPointTab,
  },

  data() {
    return {
      number_filter: null,
      city_filter: null,
      vendor_filter: null,
      fw_filter: null,
      tab: 0,
      selected: false,
      tabs: [
        {
          id: 0,
          name: "Список",
        },
        {
          id: 1,
          name: "Карта",
        },
      ],
      options: {
        sortBy: ["point_number"],
        sortDesc: [false],
      },
    };
  },
  created() {
    if (localStorage.getItem("point_filters")) {
      let params = JSON.parse(localStorage.getItem("point_filters"));
      this.city_filter = params.city ? params.city : null;
      this.vendor_filter = params.vendor ? params.vendor : null;
      this.fw_filter = params.fw ? params.fw : null;
      this.number_filter = params.search ? params.search : null;
      this.options.sortBy = params.sortBy ? params.sortBy : ["point_number"];
      this.options.sortDesc = params.sortDesc ? params.sortDesc : [false];
      this.options.page = params.page ? params.page : 1;
    }
    this.$store.dispatch("list_points", this.params);
  },
  watch: {
    params: {
      handler() {
        this.$store.dispatch("list_points", this.params);
      },
      deep: true,
    },
    tab(value) {
      if (value == 1) {
        this.init_map();
      }
    },
  },
  computed: {
    params() {
      return {
        ...this.options,
        city: this.city_filter,
        vendor: this.vendor_filter,
        fw: this.fw_filter,
        search: this.number_filter,
      };
    },
    cp_header() {
      return [
        {
          text: "Номер",
          align: "start",
          sortable: true,
          value: "point_number",
        },
        {
          text: "Модель",
          align: "start",
          sortable: true,
          value: "model",
        },
        {
          text: "Производитель",
          align: "start",
          sortable: true,
          value: "vendor",
        },
        {
          text: "Серийный номер",
          align: "start",
          sortable: true,
          value: "serial",
        },
        {
          text: "Адрес установки",
          align: "start",
          sortable: true,
          value: "address",
        },
        {
          text: "Прошивка",
          align: "start",
          sortable: true,
          value: "firmware",
        },
        {
          text: "Обновление",
          align: "start",
          sortable: true,
          value: "updated_at",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
    charge_points() {
      return this.$store.getters.POINTS;
    },
    points_loading() {
      return this.$store.getters.LOADING_POINTS;
    },
    total_points() {
      return this.$store.getters.TOTAL_POINTS;
    },
    city_items() {
      return this.$store.getters.CITY_FILTER;
    },
    vendor_items() {
      return this.$store.getters.VENDOR_FILTER;
    },
    fw_items() {
      return this.$store.getters.FW_FILTER;
    },
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search) !== -1
      );
    },
    update_selected(point_number) {
      this.$store.dispatch("load_point_info", point_number);
    },
    init_map() {
      const defaultLat = { lat: 55.75532732384285, lng: 37.633314549373964 };
      const mapContainer = this.$refs.googleMap;
      const map = new window.google.maps.Map(mapContainer, {
        zoom: 10,
        center: defaultLat,
      });
      const pointStops = [];
      this.charge_points.map((point) => {
        pointStops.push([
          { lat: point.latitude, lng: point.longitude },
          point.point_number,
          point.address,
        ]);
      });
      // Create an info window to share between markers.
      const infoWindow = new window.google.maps.InfoWindow();
      // Create the markers.
      pointStops.forEach(([position, point_number, address]) => {
        const marker = new window.google.maps.Marker({
          position,
          map,
          title: `${point_number}`,
          optimized: false,
        });
        const infoContent =
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          `<h4 id="firstHeading" class="firstHeading">Станция <b>${point_number}</b></h4>` +
          '<div id="bodyContent">' +
          `<p><b>${address}</b></p>` +
          "</div>" +
          "</div>";
        // Add a click listener for each marker, and set up the info window.
        marker.addListener("click", () => {
          infoWindow.close();
          infoWindow.setContent(infoContent);
          infoWindow.open(marker.getMap(), marker);
          this.selected = false;
          this.selected = true;
          this.update_selected(marker.getTitle());
        });
      });
    },
    row_class(item) {
      if (!item.status) {
        return 'clickable red blink'
      }
      return "clickable";
    },
    point_info(item) {
      this.$router.push({
        name: "point_info",
        params: { point_id: item.point_number },
      });
    },
    btn_text(point) {
      if (point.available) {
        return "Выключить";
      }
      return "Включить";
    },
    btn_color(point) {
      if (point.available) {
        return "red";
      }
      return "green";
    },
    enable_point(point) {
      this.$store
        .dispatch("enable_point", {
          point_number: point.point_number,
          enable: !point.available,
        })
        .then(() => {
          this.$store.dispatch("list_points");
        })
        .catch(() => {
          console.log("error");
        });
    },
    clearSelected() {
      this.selected = false;
    },
    add_point() {
      this.$store.dispatch("add_point_dialog", true);
    },
    refresh_list() {
      this.$store.dispatch("list_points", this.params);
    }
  },
};
</script>

<style scoped lang="scss">
#map {
  height: calc(100vh - 115px);
}
.fixed_height {
  height: calc(100vh - 115px);
}
</style>
